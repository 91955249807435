@import "../../sass/variables";

.clients-section {
  width: 100%;
  text-align: center;

  .clients-slider {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    height: 90px;
    width: 100%;
    padding: 4px 0;
      border-top: 1px solid #E9DBBC;
      border-bottom: 1px solid #E9DBBC;
      margin-top: 16px;
      
    .clients-container {
      height: 90px;
      width: auto;
      display: flex;
      align-items: center;
      gap: 8px;
      
  
  
      position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    // animation: moveSlideshow 48s linear infinite;
    animation: moveSlideshow 24s linear infinite;
  
  
      .client-logo {
        flex-shrink: 0;
        height: 100%;
        width: auto;
      }
      .client-separator {
        flex-shrink: 0;
        height: 1px;
        width: 30px;
        background: #E9DBBC;
      }
    }
  }

  
  
}

@media screen and (max-width: 900px) {
  .clients-section {
    .clients-slider {
      height: 48px;
      .clients-container {
        height: 48px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .clients-section {
    .main-section-heading-container .new-section-heading {
      font-size: 20px;
      padding: 0 16px;
    }
  }
}

@keyframes moveSlideshow {
  100% { 
    transform: translateX(-33.20%);  
  }
}